import { Component, Input, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import * as ebConstants from './error-banner.component.constants';
import { DtrRequestService } from '../core/services/dtr-request/dtr-request.service';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@app/core/services/routing.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { Subscription } from 'rxjs';
import { DScribeService } from '../core/services/dscribe.service';
import * as _ from 'lodash';
import { OlciAnalyticsService } from '@app/shared/services/analytics/analytics.service';
const {
    ERROR_CODE_CONTENT_MAP,
    ERROR_CODE_IMAGE_MAP
} = ebConstants;

@Component({
    selector: 'dtr-hub-error-banner',
    templateUrl: './error-banner.component.html',
    styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent implements OnInit, OnDestroy {

    campus: string;
    UI_CONSTANTS: any = {};
    errorResponse: any;
    dscribeDetails: any;
    reservationNo: string;
    wdwURL: string;
    wrapperId: string;
    dscribeSub: Subscription;
    errorCode: number;
    filteredDscribeContent: any;
    @HostBinding('class.aulani') aulani: boolean = this.globalThemeService.getCampus() === 'aulani';
    @HostBinding('class.wdw') wdw: boolean = this.globalThemeService.getCampus() === 'wdw';
    constructor(
        public router: Router,
        public globalThemeService: GlobalThemeService,
        public dtrRequestService: DtrRequestService,
        public route: ActivatedRoute,
        public routingService: RoutingService,
        public dscribeService: DScribeService,
        private olciAnalytics: OlciAnalyticsService
    ) { }

    ngOnInit() {
        this.reservationNo = this.route.snapshot.paramMap.get('reservationNo');
        this.campus = this.globalThemeService.getCampus();
        const hostName = this.globalThemeService.getHostName() === 'localhost' ?
            'latest.disneyworld.disney.go.com' : this.globalThemeService.getHostName();
        this.wdwURL = ebConstants.protocol + hostName + ebConstants.footerURL;
        this.wrapperId = ebConstants.wrapperId;
        this.dtrRequestService.currentErrorResponse.subscribe(error => {
            this.errorResponse = error;
            this.errorCode = (this.errorResponse.error && this.errorResponse.error.errorCode) || 0;
            this.addAnalyticsError(error);
        });
        this.getDScribeDetails();
    }

    getDScribeDetails() {
        this.dscribeSub = this.dscribeService.getOlciStaticDetails(null)
            .subscribe(
                (res) => {
                    this.dscribeDetails = _.filter(res.body.modules, function (module) {
                        return module.id === ebConstants.moduleId;
                    })[0].modules;
                    this.dscribeService.setOlciStaticDetails(res);
                },
                (error) => {
                    this.dscribeDetails = ebConstants.errorModule.modules;
                }
            );
    }

    tryAgain() {
        if ((
            this.errorResponse.status &&
            this.isReturnErrorCode(this.errorResponse.status)) ||
                (this.isBadRequestErrorCode(this.errorResponse.status) &&
                this.errorResponse.error && this.errorResponse.error.errorCode)) {
            this.routingService.redirectToReturnURL();
        } else {
            let routeURL = commonConstants.routeURLs.BASE_URL;
            if (this.reservationNo) {
                routeURL = routeURL + commonConstants.routeURLs.RESORT_RESERVATION + this.reservationNo;
            }
            this.routingService.routeToRouteURL(routeURL);
        }
        return false;
    }

    isReturnErrorCode(errorCode: number) {
        return errorCode === 401 || errorCode === 403;
    }

    isBadRequestErrorCode(errorCode:number) {
        return errorCode === 400;
    }

    ngOnDestroy() {
        if (this.dscribeSub) {
            this.dscribeSub.unsubscribe();
        }
    }

    getAulaniLogoAltText() {
        return ebConstants.aulaniLogo;
    }


    filterDscribeDetails(dscribeDetails = this.dscribeDetails)  {
        //once error page loads, we won't change the type of content, thus store it in local state
        if(this.filteredDscribeContent) {
            return this.filteredDscribeContent;
        }
        this.filteredDscribeContent = dscribeDetails.filter(module => 
            module.id === ERROR_CODE_CONTENT_MAP[this.errorCode || 0]
        )[0];
        //fallback incase the specific error code doesn't exist in the map.
        if(!this.filteredDscribeContent) {
            this.filteredDscribeContent = dscribeDetails.filter(module => 
                module.id === ERROR_CODE_CONTENT_MAP[0]
            )[0];
        }
        return this.filteredDscribeContent;
    }

    filterDscribeMedia(dscribeDetails = this.dscribeDetails) {
        const filtered = this.filterDscribeDetails(dscribeDetails);
        if(!filtered || !filtered.media) {
            //Fallback so an image always exists
            return {
                link: 'assets/images/goofy_scratch_head'
            };
        }
        const mediaLinkId = Object.keys(filtered.media).filter(mediaKey =>
            mediaKey === ERROR_CODE_IMAGE_MAP[this.errorCode || 0]
        )[0];
        if(!mediaLinkId) {
            const fallbackLinkId = Object.keys(filtered.media).filter(mediaKey =>
                mediaKey === ERROR_CODE_IMAGE_MAP[0]
            )[0];
            return filtered.media[fallbackLinkId];
        }
        return filtered.media[mediaLinkId];
    }

    getTitleText() {
        return this.filterDscribeDetails()
            .sections.title || '';
    }

    getTitleAltText() {
        return this.filterDscribeDetails()
            .sections.titleAccessibility || '';
    }

    getSubheadingText() {
        return this.filterDscribeDetails()
            .sections.subHeading || '';
    }

    getBodyText() {
        return this.filterDscribeDetails()
            .sections.body || '';
    }

    getLinkText() {
        return this.filterDscribeDetails()
            .sections.linkText || '';
    }

    getLinkAltText() {
        return this.filterDscribeDetails()
            .sections.linkTextAccesibility || '';
    }

    getImageSource() {
        return this.filterDscribeMedia()
            .url;
    }

    getImageClass() {
        return `${this.filterDscribeMedia()
            ?.id || 'goofy'}-img`;
    }

    /*************************
     * Analytics Model Setup
     ************************/
    addAnalyticsError(error) {
        // console.log('Adding Olci Error');
        // console.log(error);
        if (error.status) {
            // This will overwrite all the error codes currently in model..
            this.olciAnalytics.updateAnalyticsModel({
                errorCodes: `${error.status || error}`
            });
        }
    }
}
