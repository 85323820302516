import { AnalyticsService, ConfigService as AnalyticsConfigService } from '@wdpr/angular-analytics';
import { Injectable } from '@angular/core';
import { PageKeyService } from '@wdpr/ra-angular-page-key';
import { Router, NavigationEnd } from '@angular/router';
import { filter ,  delay } from 'rxjs/operators';
import { ANALYTICS_CONSTANTS } from './analytics.constants';
import { GlobalThemeService } from '../../../services/global-theme-service/global.theme.service';

@Injectable()
export class OlciAnalyticsService {
    pageKey = false;
    scheduledAnalyticsTrackPages: object;
    scheduledAnalyticsTrackLinks = [];

    constructor(
        private analyticsService: AnalyticsService,
        private analyticsConfig: AnalyticsConfigService,
        private pageKeyService: PageKeyService,
        private router: Router,
        private globalThemeService: GlobalThemeService
    ) { }

    initAnalytics() {
        // Analytics initialization
        // console.log('starting analytics');
        const analyticsAutoTrackingTimeout = ANALYTICS_CONSTANTS.AUTO_PAGE_VIEW_TRACKING_TIMING;

        let serverHost = this.globalThemeService.getHostName();
        if (this.globalThemeService.getHostPort()) {
            serverHost = serverHost + ':' + this.globalThemeService.getHostPort();
        }
        serverHost = 'https://' + serverHost;
        this.analyticsConfig.serverHost = serverHost;
        this.analyticsConfig.serverPath = ANALYTICS_CONSTANTS.ANALYTICS_API_PATH;
        this.analyticsConfig.autoPageViewTrackingTiming = analyticsAutoTrackingTimeout;
        this.initSubscribers();
    }

    initSubscribers() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.pageKey = false;
        });
        // console.log('adding subscribers for analytics');

        this.pageKeyService.pipe(
                delay(100)
            ).subscribe((message) => {
                // console.log(message);
                this.pageKey = true;
                if (!this.scheduledAnalyticsTrackPages && !this.scheduledAnalyticsTrackLinks.length) {
                    this.analyticsService.getModel();
                } else {
                    if (this.scheduledAnalyticsTrackPages) {
                        this.updateAnalytics(this.scheduledAnalyticsTrackPages);
                        delete this.scheduledAnalyticsTrackPages;
                    }

                    if (this.scheduledAnalyticsTrackLinks.length) {
                        this.scheduledAnalyticsTrackLinks.forEach(data => {
                            this.trackLink(data.trackLinkEvent, data.modelProps);
                        });
                        this.scheduledAnalyticsTrackLinks = [];
                    }
                }
            }, (error) => {
                console.log(error);
            });
    }

    getAnalyticsModelFromPageData(pageViewData) {
        const analyticsModel = {
            site: pageViewData.site,
            pageId: pageViewData.pageId,
            siteSection: pageViewData.siteSection,
            events: pageViewData.events,
            selfServiceType: pageViewData.selfServiceType,
            store: pageViewData.store,
            customerId: pageViewData.swid,
            experienceId: '', // this should be the xid...not sure where this comes from
            guestType: pageViewData.guestType,
            guestAffiliations: '', // this should be guest Affiliations...? OLCI doesn't have this data..
            errorCodes: '', // this should be comma separated list of error codes shown to the guest
            homeState: pageViewData.mdxAccountInfo ?
                pageViewData.mdxAccountInfo.address ?
                    pageViewData.mdxAccountInfo.address.state :
                    '' :
                '',
            homeZip: pageViewData.mdxAccountInfo ?
                pageViewData.mdxAccountInfo.address ?
                    pageViewData.mdxAccountInfo.address.postalCode :
                    '' :
                '',
            products: pageViewData.productsData
        };

        return analyticsModel;
    }

    /**
     *
     * @param modelProps The data to be added to the model
     */
    updateAnalyticsModel(modelProps?: object) {
        if (this.pageKey) {
            this.updateAnalytics(modelProps);
        } else {
            this.scheduledAnalyticsTrackPages = modelProps;
        }
    }

    updateAnalytics(modelProps?: object) {
        this.analyticsService.getModel().then(model => {
            // This allows us to modify the errors object without overwriting it..
            if (modelProps['errorCodes']) {
                const errorCodes = model['errorCodes'] ?
                    `${model['errorCodes']},${modelProps['errorCodes']}` :
                    `${modelProps['errorCodes']}`;
                modelProps['errorCodes'] = errorCodes;
            }
            Object.assign(model, modelProps || {});
            // console.log('new Model', model);
            this.analyticsService.update();
        });
    }

    updateGlobalProcessors(data?: object) {
        const processor = {
            id: data['pageId'],
            name: data['pageId']
        };

        Object.assign(processor, data);
        this.analyticsConfig.addGlobalProcessors(processor);
    }

    trackLink(trackLinkEvent: string, modelProps?: object) {
        if (this.pageKey) {
            this.analyticsService.getModel().then(model => {
                Object.assign(model, modelProps || {});
                model['events'] = '';
                model['linkId'] = trackLinkEvent;
                this.analyticsService.trackLink(model);
            });
        } else {
            this.scheduledAnalyticsTrackLinks.push({
                trackLinkEvent,
                modelProps
            });
        }
    }
}
