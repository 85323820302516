import { Component, OnInit, OnDestroy, HostBinding, ViewChild } from '@angular/core';
import { DataService } from '@core/services';
import { Reservation } from '@app/core/models/reservation.model';
import { Subscription } from 'rxjs';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import * as homeConstants from './home.component.constants';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { RoutingService } from '@app/core/services/routing.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { DScribeService } from '@app/core/services/dscribe.service';
import { ReservationService } from '@app/core/services/reservation.service';
import { cacheSessionIdKey, commonId, olciHeaderId } from 'app/core/constants/common-constants';
import { OlciAnalyticsService } from '@app/shared/services/analytics/analytics.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfigService } from '@app/core/config.service';
import { PrearrivalInfo } from '@app/core/models/pre-arrival-info.model';
import { CacheDetails } from '@app/core/models/cache-details.model';
import { Accommodation } from '@app/core/models/accommodation.model';
import { AuthService } from "@core/services/auth/auth.service";
import {distinctUntilChanged, filter} from "rxjs/operators";

@Component({
    selector: 'dtr-hub-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

    private didappid: string;
    private responderPage: string;
    reservation: Reservation;
    roomCheckInSub: Subscription;
    dscribeSub: Subscription;
    campus: string;
    wdwURL: string;
    wrapperId: string;
    errorResponse: any;
    dscribeDetails: any;
    error: any;
    isSYWSet: boolean;
    isSYWEligible: boolean;
    isRRNSet: boolean;
    isResortArrivalSet: boolean;
    isMagicBandSet: boolean;
    isPinSet: boolean;
    isPetRegistrationSet: boolean;
    allSectionsOnFile: boolean;
    isHotelAccountChargesSet: boolean;
    reservationAddressIsValid: boolean;
    @HostBinding('class.aulani') aulani: boolean = this.globalThemeService.getCampus() === 'aulani';
    @HostBinding('class.wdw') wdw: boolean = this.globalThemeService.getCampus() === 'wdw';
    UI_CONSTANTS: any = {};
    loading = false;
    saving = false;
    saveInProgress = false;
    showGlobalError = false;
    globalErrorMsg: string[];
    paymentSheetLoaded: boolean;
    tokenDetailsPresent = false;
    appSessionDetails: any;
    aboutOcliData: any;
    hotelAccChrData: any;
    dscribeCommonData: any;
    olciHeaderData: any;
    tncVersion: string;
    config: any;
    reservationNo: number;
    isHACExpanded = false;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        public dataService: DataService,
        public globalThemeService: GlobalThemeService,
        public routingService: RoutingService,
        public reservationService: ReservationService,
        public dscribeService: DScribeService,
        private olciAnalyticsService: OlciAnalyticsService,
        public configService: ConfigService,
        public authService: AuthService
    ) {
        this.initializeConstants();
        this.config = this.configService.config;
      this.campus = this.globalThemeService.getCampus();
    }

  ngOnInit() {
    if (this.campus === "wdw" && !this.authService.isInitialized.getValue()) {
      document.addEventListener("navigation", (event: any) => {
        if (event.detail.event === "oneId-init") {
          this.authService.initialize().then(() => {
            this.subscribeToInitialization();
          });
        } else if (event.detail.event === 'oneId-low-trust') {
          this.authService.reAuth().then(() => {
            console.info("oneId-low-trust on navigation", event);
          }).catch(() => {
            location.reload();
          });

        } else {
          console.info("Other one id event", event);
        }
      });
    } else {
      this.loadData();
    }
    this.initializeNavUIFooter();
  }

  subscribeToInitialization() {
    this.authService.isInitialized
      .pipe(
        distinctUntilChanged(),
        filter(isInitialized => isInitialized === true)
      )
      .subscribe(() => {
        console.log('Authentication state changed to true, acting on it.');
        this.loadData();
      });
  }

    saveData(): void {
        this.saving = true;
        const that = this;
        this.saveInProgress = true;
        this.paymentSheetLoaded = this.reservationService.isPaymentSheetLoaded();

        this.validateOLCIFormAsync()
            .then(function olciFormValidationHandler(isValid) {
                if (!isValid) {
                    that.setGlobalError(that.aboutOcliData.descriptions[0].sections.generalPreSave);
                    return;
                }
                that.postOLCI();
            });
    }

  loadData(): void {
    this.reservationNo = parseInt(this.route.snapshot.paramMap.get('reservationNo'), 10);
    this.loading = true;
    this.roomCheckInSub = this.dataService.getRoomcheckinData(this.reservationNo)
      .subscribe(
        (res) => {
          this.reservation = res;
          this.tncVersion = res.generalInfo.termsConditionsVersion;
          const petTermsConditions = res.generalInfo['petTermsConditions'];
          if (petTermsConditions) {
            const titleSplitter = '</h1>';
            const subtitleSplitter = '</h3>';
            let [title, remainsWSubtitle] = petTermsConditions.split(titleSplitter);
            title += titleSplitter;
  
            let [subtitle, body] = remainsWSubtitle.split(subtitleSplitter);
            subtitle += subtitleSplitter;
  
            this.reservation.generalInfo.petPolicyTermsConditionsTitle = title;
            this.reservation.generalInfo.petPolicyTermsConditionsSubTitle = subtitle;
            this.reservation.generalInfo.petPolicyTermsConditionsBody = body;
          }

          const tpsDetails: any = this.reservation.travelPlanSegmentDetails;
          const facilityId = tpsDetails.accommodation[0].facilityId;
          this.reservationService.facilityId = facilityId;
          const sessionId = sessionStorage.getItem(cacheSessionIdKey);
          if (sessionId) {
            this.dataService.getCacheDetails(sessionId, this.reservationNo).subscribe((cacheData: CacheDetails) => {
              this.initializeData(res, this.reservationNo, facilityId);
              this.setCacheData(res, cacheData, tpsDetails);
            }, error => this.initializeData(res, this.reservationNo, facilityId));
          } else {
            this.initializeData(res, this.reservationNo, facilityId);
          }
        },
        (error) => {
          this.loading = false;
          if (!this.authService.isUnauthorizedError(error)) {
            this.routeForError(error.error, this.reservationNo);
          }
        }
      );
  }

  private initializeNavUIFooter() {
    this.campus = this.globalThemeService.getCampus();
    const hostName = this.globalThemeService.getHostName() === 'localhost' ?
      'latest.disneyworld.disney.go.com' : this.globalThemeService.getHostName();
    this.wdwURL = homeConstants.protocol + hostName + homeConstants.footerURL;
    this.wrapperId = homeConstants.wrapperId;
  }

    initializeData(res, reservationNo, facilityId) {
        this.initializeResortArrivalSaveValue(res.prearrivalInfo);
        this.initializeRoomcheckinPayload();
        this.getDScribeDetails(reservationNo, facilityId);
        this.setDetailsOnFile();
        this.initializeAnalyticsModel();
    }

    setCacheData(res, cacheData, tpsDetails) {
        this.isHACExpanded = cacheData.isHACExpanded;
        // res.chargeAccountDetails = JSON.parse(JSON.stringify(cacheData.chargeAccountDetails));
        this.reservationService.roomcheckinPayload.chargeAccountDetails = JSON.parse(JSON.stringify(cacheData.chargeAccountDetails));
        this.reservationService.setHAPSaveSet(cacheData.showHAPEditButton);
        res.prearrivalInfo.arrivalDepartureInfo.arrivalTime = cacheData.resortArrivalTime || null;
        res.prearrivalInfo.serviceYourWayOptIn = cacheData.houseKeepingOptIn || null;
        res.prearrivalInfo.mobilePhoneNumber = cacheData.contactInfo.mobileNumber || null;
        res.prearrivalInfo.emailAddress = cacheData.contactInfo.emailAddress || null;
        for (const key in cacheData.address) {
            if (cacheData.address[key] !== null && cacheData.address[key] !== '') {
                const primaryGuest = this.getPrimaryGuest(this.reservation.travelPlanSegmentDetails['accommodation']);
                if (Object.keys(primaryGuest.primaryAddress).length !== 0) {
                    primaryGuest.primaryAddress[key] = cacheData.address[key];
                } else {
                    res.mdxAccountInfo.address[key] = cacheData.address[key];
                }
            }
        }
        if (cacheData.roomRequest && cacheData.roomRequest.length) {
            const updatedObject = cacheData.roomRequest.find(item => item.code === commonConstants.TOPRR);
            if (updatedObject) {
                const index = tpsDetails.accommodation[0].profiles.findIndex(item => item.code === commonConstants.TOPRR);
                if (index !== -1) {
                    tpsDetails.accommodation[0].profiles[index] = updatedObject;
                }
            }
            cacheData.roomRequest.forEach(sourceItem => {
                if (sourceItem.code !== commonConstants.TOPRR) {
                    tpsDetails.accommodation[0].profiles.push(sourceItem);
                }
            });
        }
        if (cacheData?.petCount >= 0) {
            this.reservationService.roomcheckinPayload.preferences = { petInfo: { petCount: cacheData.petCount } };
        }
    }

    getPrimaryGuest(accommodation: Accommodation[]) {
        const guestReferences = [];
        _.forEach(accommodation, function (accommodationDetail) {
            _.forEach(accommodationDetail.guestReferences, function (guestReference) {
                guestReferences.push(guestReference);
            });
        });
        return _.find(guestReferences, guestReference => guestReference.guest.primaryGuest === true).guest;
    }

    initializeResortArrivalSaveValue(preArrivalInfo: PrearrivalInfo): void {
        if (!preArrivalInfo.showOlciEta) {
            this.reservationService.setRatSaveSet(true);
        }
    }

    validateOLCIFormAsync(): Promise<any> {
        const that = this;

        const promise = new Promise((resolve, reject) => {
            if (this.paymentSheetLoaded && !this.tokenDetailsPresent) {
                this.globalThemeService.getWindow()['paymentSheet'].validateForm()
                    .then(function validateFormSuccess() {
                        that.reservationService.setHACSaveSet(true);
                        resolve(that.validateOLCIForm());
                    })
                    .catch(function validateFormError(e) {
                        that.reservationService.setHACSaveSet(false);
                        resolve(that.validateOLCIForm());
                    });
            } else {
                resolve(this.validateOLCIForm());
            }
        });
        return promise;
    }

    validateOLCIForm(): boolean {
        this.checkPetModule();
        return this.reservationService.isRRNSaveSet() &&
            this.reservationService.isHACSaveSet() &&
            this.reservationService.isHAPSaveSet() &&
            this.reservationService.isTnCSaveSet() &&
            this.reservationService.isPetTnCSaveSet() &&
            this.reservationService.isRatSaveSet() &&
            this.reservationService.getAddressIsValid();
    }

    setGlobalError(errorMsg: string) {
        this.globalErrorMsg = [];
        this.saving = false;
        this.showGlobalError = true;
        this.globalErrorMsg.push(errorMsg);
    }

    postOLCI() {
        this.saveInProgress = false;
        const that = this;
        if (this.paymentSheetLoaded) {
            if (this.tokenDetailsPresent) {
                this.getSessionDetails();
            } else {
                this.globalThemeService.getWindow()['paymentSheet'].process()
                    .then(function processSuccess() {
                        that.tokenDetailsPresent = true;
                        that.getSessionDetails();
                    })
                    .catch(function processError(e) {
                        that.setGlobalError(that.hotelAccChrData.descriptions[0].sections.paymentInfoError);
                    });
            }
        } else {
            this.saveOLCI();
        }
    }

    getSessionDetails() {
        const sessionToken = this.reservationService.getAPPSessionToken();
        const isBeach = this.reservationService
            .isBeachResortReservation(this.reservation.travelPlanSegmentDetails['accommodation']);
        const appClientHeader = this.globalThemeService.getAPPClientHeader(isBeach);
        this.dataService.getAPPSessionDetails(sessionToken, appClientHeader)
            .subscribe(
                (res) => {
                    this.appSessionDetails = res.body;
                    this.mapAPPDetailsForSave();
                    this.saveOLCI();
                },
                (error) => {
                    this.setGlobalError(this.hotelAccChrData.descriptions[0].sections.paymentInfoError);
                }
            );
    }

    mapAPPDetailsForSave() {
        const payload = {
            'folioId': 0,
            'card': this.appSessionDetails.card,
            'useForIncidentals': true,
            'externalReferenceSource': '',
            'externalReferenceValue': 0,
            'lastName': this.appSessionDetails.lastName
        };
        payload.folioId = this.reservationService.roomcheckinPayload.settlementMethods.folioId;
        payload.externalReferenceSource = 'DREAMS_TP';
        payload.externalReferenceValue = this.reservation.travelPlanSegmentDetails.travelPlanId;

        this.reservationService.roomcheckinPayload.settlementMethods = payload;
    }

    saveOLCI() {
        const byPassAuth = this.paymentSheetLoaded ? this.paymentSheetLoaded : false;
        const roomCheckInPayload = this.reservationService.roomcheckinPayload;
        roomCheckInPayload.generalInfo.termsConditionsVersion = this.tncVersion;
        this.roomCheckInSub = this.dataService.postRoomcheckinData(roomCheckInPayload, byPassAuth)
            .subscribe(
                (res) => {
                    this.saveInProgress = false;
                    this.saving = false;
                    const sessionId = sessionStorage.getItem(cacheSessionIdKey);
                    if (sessionId) {
                        this.dataService.delCacheDetails(sessionId, this.reservationNo).subscribe(cacheData => {
                            sessionStorage.removeItem(cacheSessionIdKey);
                            this.routingService.redirectToReturnURL();
                        }, error => this.routingService.redirectToReturnURL());
                    } else {
                        this.routingService.redirectToReturnURL();
                    }
                },
                (error) => {
                    this.saveInProgress = false;
                    this.saving = false;
                    this.setGlobalError(this.aboutOcliData.descriptions[0].sections.generalPostSave);
                }
            );
    }

    getDScribeDetails(reservationNo: number, facilityId: number) {
        this.dscribeSub = this.dscribeService.getOlciStaticDetails(facilityId)
            .subscribe(
                (res) => {
                    this.loading = false;
                    this.globalThemeService.setRequestedLanguage(res.body.requestedLanguage);
                    this.dscribeDetails = _.filter(res.body.modules, function (module) {
                        return module.id === homeConstants.moduleId;
                    })[0].modules;
                    this.getCommonData(this.dscribeDetails);
                    this.dscribeService.setOlciStaticDetails(res);
                },
                (error) => {
                    this.loading = false;
                    if (!this.authService.isUnauthorizedError(error)) {
                        this.routeForError(error.error, reservationNo);
                    }
                }
            );
    }
    checkPetModule() {
        const isPetModule = this.dscribeDetails.some(
          (module) => module.id === commonConstants.petDetailsDscribeModule
        );
        if(!isPetModule || this.reservationService.petCount === 0) {
            this.reservationService.setPetTnCSaveSet(true);
        }
    }

    getCommonData(dsbData) {
        this.aboutOcliData = _.find(dsbData, function(module) {
            return module.id === homeConstants.OcliMouduleId;
        });
        this.hotelAccChrData = _.find(dsbData, function(module) {
            return module.id === homeConstants.hotelAccChrgModuleId;
        });
        this.dscribeCommonData = _.find(dsbData, function(module) {
            return module.id === commonId;
        });
        this.olciHeaderData = _.find(dsbData, function(module) {
            return module.id === olciHeaderId;
        });
    }

    routeForError(error, reservationNo) {
        this.errorResponse = error;
        const routeURL = commonConstants.routeURLs.SLASH +
            commonConstants.routeURLs.BASE_URL +
            commonConstants.routeURLs.ERROR +
            commonConstants.routeURLs.SLASH + reservationNo;
        this.routingService.routeToRouteURL(routeURL);
    }

    cancelOLCI() {
        const sessionId = sessionStorage.getItem(cacheSessionIdKey);
        if (sessionId) {
            this.dataService.delCacheDetails(sessionId, this.reservationNo).subscribe(cacheData => {
                sessionStorage.removeItem(cacheSessionIdKey);
                this.routingService.redirectToReturnURL();
            }, error => this.routingService.redirectToReturnURL());
        } else {
            this.routingService.redirectToReturnURL();
        }
    }

    private initializeConstants() {
        this.UI_CONSTANTS.save = homeConstants.save;
        this.UI_CONSTANTS.saveAlt = homeConstants.saveAlt;
        this.UI_CONSTANTS.cancel = homeConstants.cancel;
        this.UI_CONSTANTS.cancelAlt = homeConstants.cancelAlt;
    }

    setDetailsOnFile() {
        this.allSectionsOnFile = false;
        this.isSYWEligible = this.reservationService.isSYWEligible(this.reservation);
        this.isSYWSet = this.reservationService.isSYWSet(this.reservation);
        this.isRRNSet = this.reservationService.isRRNSet(this.reservation);
        this.isResortArrivalSet = this.reservationService.isResortArrivalSet(this.reservation);
        this.isMagicBandSet = this.campus === commonConstants.wdw_campus ?
            this.reservationService.isMagicBandSet(this.reservation) : !this.config.showAulaniMagicBand;
        this.isPinSet = this.reservationService.isPinSet(this.reservation);
        this.isPetRegistrationSet = this.reservationService.isPetRegistrationSet(this.reservation);
        this.isHotelAccountChargesSet = this.reservationService.isHotelAccountChargesSet(this.reservation);
        this.reservationAddressIsValid = this.reservationService.isReservationAddressValid(this.reservation);
        if ((!this.isSYWEligible || this.isSYWSet) && this.isRRNSet && this.isResortArrivalSet && this.isPinSet
            && this.isPetRegistrationSet && this.isHotelAccountChargesSet && this.reservationAddressIsValid) {
            this.allSectionsOnFile = true;
        }
    }

    initializeRoomcheckinPayload() {
        const payload = {
            'generalInfo': {
                'resortReservationId': this.reservation.generalInfo.resortReservationId,
                'termsConditionsType': homeConstants.tncType[this.globalThemeService.getCampus()],
                'termsConditions': this.reservation.generalInfo.termsConditions,
                'dtrTermsConditionsTitle': this.reservation.generalInfo.dtrTermsConditionsTitle,
                'dtrTermsConditions': this.reservation.generalInfo.dtrTermsConditions,
                'dtrTermsConditionsMore': this.reservation.generalInfo.dtrTermsConditionsMore,
                'petPolicyTermsConditionsTitle': this.reservation.generalInfo.petPolicyTermsConditionsTitle,
                'petPolicyTermsConditionsSubTitle': this.reservation.generalInfo.petPolicyTermsConditionsSubTitle,
                'petPolicyTermsConditionsBody': this.reservation.generalInfo.petPolicyTermsConditionsBody
            },
            'prearrivalInfo': {
                'arrivalDepartureInfo': {},
                'mobilePush': this.reservation.prearrivalInfo.mobilePush,
                'showOlciEta': this.reservation.prearrivalInfo.showOlciEta
            },
            'profileInfos': [],
            'address': {},
            'chargeAccountDetails': {},
            'settlementMethods': this.getSettlementMethodDetails()
        };
        this.reservationService.roomcheckinPayload = payload;
        if (this.reservation && this.reservation.preferences && this.reservation.preferences.petInfo) {
            this.reservationService.roomcheckinPayload.preferences = {
                petInfo: this.reservation.preferences.petInfo
            };
        }
    }

    getSettlementMethodDetails() {
        const card = this.reservation.settlementMethods.card;
        if (card) {
            return {
                'card': {
                    'nameType': homeConstants.cardNameTypes[card.nameType] || card.nameType,
                    'type': card.type,
                    'name': card.name,
                    'brandName': card.brandName,
                    'number': card.number,
                    'expiration': {
                        'year': card.expiration.year,
                        'month': card.expiration.month
                    },
                    'address': card.address,
                    'retrievalReferenceNumber': card.retrievalReferenceNumber,
                    'retrievalReferenceNumberKey': card.retrievalReferenceNumberKey
                },
                'useForIncidentals': this.reservation.settlementMethods.incidentalUse,
                'externalReferenceSource': 'DREAMS_TP',
                'externalReferenceValue': this.reservation.travelPlanSegmentDetails.travelPlanId,
                'folioId': this.reservation.settlementMethods.folioId
            };
        } else {
            return {
                'card': null,
                'useForIncidentals': this.reservation.settlementMethods.incidentalUse,
                'externalReferenceSource': 'DREAMS_TP',
                'externalReferenceValue': this.reservation.travelPlanSegmentDetails.travelPlanId,
                'folioId': this.reservation.settlementMethods.folioId
            };
        }
    }

    ngOnDestroy(): void {
        this.roomCheckInSub.unsubscribe();
        if (this.dscribeSub) {
            this.dscribeSub.unsubscribe();
        }
        localStorage.removeItem(commonConstants.olciTncLocalStorageName);
        localStorage.removeItem(commonConstants.olciTncStaticContentLocalStorageName);
    }

    // shouldHideResortArrival will return true if this section should be hidden, otherwise returns false
    // Thus, we are checking if we should _not_ hide the section, and that we have all the info we need to show it
    shouldResortArrivalSectionDisplay(): Boolean {
        return !this.reservationService.getShouldHideEta(this.reservation) &&
            (this.dscribeDetails && this.reservation && !this.isResortArrivalSet);
    }

    /**************************
     *   Analytics
     *************************/
    initializeAnalyticsModel(): void {
        this.updateAnalyticsModel();
    }

    updateAnalyticsModel() {
        const { analyticsConstants } = homeConstants;
        const productsData = this.getProductsData();
        const pageViewData = {
            mdxAccountInfo: this.reservation.mdxAccountInfo,
            swid: this.authService.swid,
            site: this.globalThemeService.getCampus(),
            pageId: analyticsConstants.pageId,
            siteSection: analyticsConstants.siteSection,
            events: analyticsConstants.events,
            selfServiceType: analyticsConstants.selfServiceType,
            store: analyticsConstants.store,
            guestType: analyticsConstants.guestType,
            productsData: productsData
        };
        const analyticsModel = this.olciAnalyticsService.getAnalyticsModelFromPageData(pageViewData);

        this.olciAnalyticsService.updateAnalyticsModel(analyticsModel);
    }

    getProductsData() {
        const { analyticsConstants, ageTypes } = homeConstants;
        const {
            travelPlanSegmentDetails: {
                accommodations
            }
        } = this.reservation;

        if (!accommodations) {
            return [];
        }

        const results = accommodations.map((accommodation) => {
            const bookedAdults = this.getCountOfGuestsByAgeType(accommodation.guestReferences, ageTypes.adult);
            const bookedChildren = this.getCountOfGuestsByAgeType(accommodation.guestReferences, ageTypes.child);
            const bookedInfants = this.getCountOfGuestsByAgeType(accommodation.guestReferences, ageTypes.infant);
            const arrivalDate = accommodation &&
                                accommodation.period &&
                                accommodation.period.startDate ||
                                '';
            const lengthOfStay = this.getLengthOfStay(accommodation.period);

            return {
                productType: analyticsConstants.productType,
                // Not sure what productId should be? Currently setting to the accommodationId..
                productId: accommodation.accommodationId,
                bookedAdults,
                bookedChildren,
                bookedInfants,
                bookedArrivalDate: arrivalDate,
                bookedLengthOfStayNights: lengthOfStay
                // TODO CIMA Analyst requested this value as well...but OLCI does not have the bookingDate
                // available to be able to calculate this value...
                // daysBetweenBookingAndArrival
            };
        });

        return results;
    }

    getCountOfGuestsByAgeType(guestReferences, ageType) {
        return guestReferences.reduce((count, guestRef) =>
            guestRef && typeof(guestRef.ageType === 'string') &&
            guestRef.ageType.toUpperCase() === ageType ?
            count + 1 :
            count,
            0);
    }

    getLengthOfStay(period) {
        if (!period) {
            return 0;
        }

        const start = moment(period.startDate);
        const end = moment(period.endDate);

        return end.diff(start, 'days');
    }

    showMagicBandComponent(): boolean {
        return this.dscribeDetails &&
            this.reservation &&
            !this.isMagicBandSet &&
            this.dscribeDetails.some(module => module.id === commonConstants.magicBandDscribeModule);
    }

    showPetRegistrationComponent(): boolean {
        return this.dscribeDetails && this.campus === commonConstants.wdw_campus &&
            this.reservation &&
            !this.isPetRegistrationSet &&
            this.dscribeDetails.some(module => module.id === commonConstants.petDetailsDscribeModule);
    }
}
