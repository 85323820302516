import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { GlobalThemeService } from '../services/global-theme-service/global.theme.service';
import { defaultLanguage } from '../core/constants/common-constants';

@Pipe({
  name: 'dateTimeZone'
})
export class DateTimeZonePipe implements PipeTransform {

  constructor(
    public globalThemeService: GlobalThemeService
  ) { }

  transform(date: any, timezone: string ): any {
    const value: Date = typeof date == 'string' ? new Date(date) : date;
    const format: string = 'fullDate';
    const zFormat: string = 'Z';
    const lang: string = this.globalThemeService.getRequestedLanguage() || defaultLanguage;
    const timezoneOffset = moment(value).tz(timezone).format(zFormat);
    const datePipe: DatePipe = new DatePipe(lang);
    return datePipe.transform(value, format, timezoneOffset);
  }
}
