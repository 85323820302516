import { Injectable } from '@angular/core';
import * as commonConstants from 'app/core/constants/common-constants';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoggerService } from '@wdpr/ra-angular-logger';
import * as _ from 'lodash';
import { Subject ,  BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@app/core/services/routing.service';
import {MaskUtil} from '@app/shared/utils/masking/mask-util';
import {maskConfig} from '@app/shared/utils/masking/mask-config';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class DtrRequestService {
    errorResponse = new BehaviorSubject({});
    currentErrorResponse = this.errorResponse.asObservable();

    constructor(private _http: HttpClient, private logger: LoggerService,
        private route: ActivatedRoute, private maskUtil: MaskUtil,
        private router: RoutingService, private authService: AuthService) {
    }

    httpRequest(request, operation) {
        let logRequest, startTimeStamp;
        const that = this;
        logRequest = {
            'service-name': commonConstants.serviceName,
            'service-operation': operation,
            'type': commonConstants.serviceType.OUTBOUND_REQUEST,
            'params': this.route.snapshot.queryParams,
            'endpoint': request.url,
            'body': request.data,
            'start': moment().format(commonConstants.moment.format)
        };

        const formattedRequestBody = formatRequestResponse(request.data);
        if (formattedRequestBody) {
            logRequest.body = JSON.parse(formattedRequestBody);
        }
        const httpSubject = new Subject<any>();
        const params = [request.url];
        if (!_.isUndefined(request.data)) {
            params.push(request.data);
        }

        let queryParams: HttpParams = null;
        if (!_.isUndefined(request.params)) {
            queryParams = new HttpParams({fromObject: request.params});
        }

        let customHeaders: HttpHeaders = null;
        if (!_.isUndefined(request.headers)) {
            customHeaders = request.headers;
        }

        startTimeStamp = moment().format(commonConstants.moment.unixTimeStamp);

        function successHandler(response) {
            const stoptStamp = moment().format(commonConstants.moment.unixTimeStamp);
            logRequest.stop = moment().format(commonConstants.moment.format);
            const duration = that.getDuration(stoptStamp, startTimeStamp);
            logRequest.duration = duration + 'ms';
            logRequest.headers = that.getHeaders(response.headers);
            that.logger.log(logRequest);

            const formattedResponseBody = formatRequestResponse(response.body);
            const responseLogger = () => {
                const logResponse: any = {
                    'service-name': commonConstants.serviceName,
                    'service-operation': operation,
                    'headers': that.getHeaders(response.headers),
                    'type': commonConstants.serviceType.OUTBOUND_RESPONSE,
                    'http-status': response.status,
                    'body': JSON.parse(formattedResponseBody)
                };
                that.logger.log(logResponse);
            };

            responseLogger();

            return response;
        }

        function formatRequestResponse(data) {
            let responseBody = that.getJSONStringify(data);
            if (maskConfig[logRequest['service-operation']]) {
                const convertedJsonResponse = that.convertIntoJson(responseBody);
                const maskedResponse = that.maskUtil
                                        .retrieveMaskedObject(
                                                logRequest['service-operation'],
                                                convertedJsonResponse);
                responseBody = that.getJSONStringify(maskedResponse);
            }
            return responseBody;
        }

        // Function used as to determine if we should redirect user to login page.
        function isUnauthorizedError(err) {
            const unauthorizedCode = 401;
            if (!err || !err.status) {
                return false;
            }
            return err.status === unauthorizedCode;
        }

        function isForbiddenError(err) {
            const forbiddenCode = 403;
            if (!err || !err.status) {
                return false;
            }
            return err.status === forbiddenCode;
        }

        function errorHandler(reason) {
            logRequest.stop = moment().format(commonConstants.moment.format);
            const stoptStamp = moment().format(commonConstants.moment.unixTimeStamp);
            const duration = that.getDuration(stoptStamp, startTimeStamp);
            logRequest.duration = duration + 'ms';
            logRequest.headers = that.getHeaders(reason.headers);
            that.logger.log(logRequest);

            const logResponse: any = {
                'service-name': commonConstants.serviceName,
                'service-operation': operation,
                'headers': that.getHeaders(reason.headers),
                'type': commonConstants.serviceType.OUTBOUND_RESPONSE,
                'error': reason.error,
                'http-status': reason.status
            };
            
            that.logger.error(logResponse);

            // Check for 401 or 403 error status. if so, redirect to
            // Login screen so we can login then try again..
            if (isForbiddenError(reason)) {
                that.authService.logout().then(res => {
                    that.logger.log('Logout Success');
                    that.router.redirectToHomeURL();
                }).catch(error => {
                    that.logger.log('Logout Error'); // Error out when already logged out or no established session
                    that.router.redirectToHomeURL();
                });
            } else if (isUnauthorizedError(reason)) {
                that.router.redirectToLoginScreen();
            } else {
                that.errorResponse.next(reason);
            }
        }

        this._http[_.lowerCase(request.method)](...params, {
            observe: 'response',
            params: queryParams,
            headers: customHeaders
        }).subscribe(
                suc => {
                    successHandler(suc);
                    httpSubject.next(suc);
                },
                err => {
                    errorHandler(err);
                    httpSubject.error(err);
                }
            );
        return httpSubject.asObservable();
    }

    getJSONStringify (jsonSting: string) {
        return jsonSting ? JSON.stringify(jsonSting) : jsonSting;
    }

    convertIntoJson (response) {
        let resObj;
        if (response !== undefined && response !== '') {
            if (typeof response === 'string') {
                try {
                    resObj = JSON.parse(response);
                } catch (e) {
                    resObj = '';
                }
            } else {
                resObj = response;
            }
        }
        return resObj;
    }

    private getHeaders(headers: HttpHeaders) {
        if (!headers || !headers.keys()) {
            // Why are there no headers??
            return '';
        }
        return _.reduce(headers.keys(), function (arr, key) {
            const obj = {};
            obj[key] = headers.getAll(key).join(',');
            arr.push(obj);
            return arr;
        }, []);
    }

    private getDuration(stoptStamp, startTimeStamp) {
        return stoptStamp - startTimeStamp;
    }

}
