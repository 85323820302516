import { Injectable, Injector } from '@angular/core';
import { CookieServices } from '@app/core/services/cookie-service/cookie-service.component';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '@app/core/config.service';
import * as moment from 'moment';

import { defaultLanguage } from '@app/core/constants/common-constants';
import {AuthService} from "@core/services/auth/auth.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    token: string;
    conversationId: string;
    envOverride: string;
    headers: HttpHeaders;
    cookieService: CookieServices;

    constructor(
        private injector: Injector,
        private configService: ConfigService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/config')) {
            return next.handle(req);
        }

        const globalThemeService = this.injector.get(GlobalThemeService);
        const authService = this.injector.get(AuthService);
        this.cookieService = this.injector.get(CookieServices);
        const config: any = this.configService.config;
        this.token = authService.accessToken;
        this.conversationId = this.cookieService.getConverationId();
        this.envOverride = this.cookieService.getEnvOverride();
        this.headers = new HttpHeaders();
        if (authService.swid) {
            this.headers = this.headers.append('swid', authService.swid);
        }

        if (this.token) {
            this.headers = this.headers.append('authorization', 'BEARER ' + this.token);
        }
        this.headers = this.headers.append('x-app-id', config.xAppIdHeader[globalThemeService.getCampus()]);
        this.headers = this.headers.append('x-disney-ui', 'web');
        this.headers = this.headers.append('x-request-timestamp', moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        const lang = req.headers['x-disney-content-lang'] ||
            req.headers['Accept-Language'] ||
            this.getCookieLanguageString(req) ||
            defaultLanguage;
        this.headers = this.headers.append('x-disney-content-lang', lang);

        // Get the app client Id header add it to the intercepted request
        // Use the req.headers value if it exists (APP Flows),
        //  otherwise recalculate the value (value won't matter in Non-APP flows)
        const inferredPaymentUiHeader = req.headers.get('x-disney-app-client-id') ||
            globalThemeService.getAPPClientHeader();
        this.headers = this.headers.append('x-disney-app-client-id', inferredPaymentUiHeader);

        delete this.headers['origin'];

        req = req.clone({
            headers: this.headers,
            withCredentials: true
        });
        return next.handle(req);
    }

    // This currently isn't used. This was a stop gap solution for getting Language header for Aulani.
    // The My Aulani Vacation site doesn't support multiple languages, only english, so this isn't needed
    getCookieLanguageString(req) {
        const langCookie = this.cookieService.getLocale();
        if (!langCookie) {
            return void 0;
        }
        const decodedCookie = decodeURIComponent(langCookie);
        try {
            const langObj = JSON.parse(decodedCookie);
            const lang = langObj.preferredLanguage;
            return lang;
        } catch (err) {
            return void 0;
        }
    }
}

