import {Component, OnInit, Input, OnDestroy, HostBinding, ElementRef} from '@angular/core';
import { DataService } from '../core/services/data/data.service';
import { ReservationSummary } from '@app/core/models/reservation-summary.model';
import { Subscription } from 'rxjs';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import * as rdConstants from './reservation-details.component.constants';
import { DatePipe } from '@angular/common';
import { DateTimeZonePipe } from '@app/pipes/date-time-zone.pipe';
import { Router } from '@angular/router';
import { RoutingService } from '@app/core/services/routing.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { DScribeService } from '../core/services/dscribe.service';
import * as _ from 'lodash';
import * as homeConstants from "@app/home/home.component.constants";
import {AuthService} from "@core/services/auth/auth.service";
import {distinctUntilChanged, filter} from "rxjs/operators";

@Component({
    selector: 'dtr-hub-reservation-details',
    templateUrl: './reservation-details.component.html',
    styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit, OnDestroy {

    reservationSummary: ReservationSummary;
    reservationSummarySub: Subscription;
    dscribeSub: Subscription;
    campus: string;
    resSummary: any;
    UI_CONSTANTS: any = {};
    resortReservations: any;
    dscribeDetails: any;
    radioOption: string;
    displayReservationSelectionDetails: boolean;
    errorResponse: any;
    error: any;
    loading = false;
    wdwURL: string;
    wrapperId: string;

    @HostBinding('class.aulani') aulani: boolean = this.globalThemeService.getCampus() === 'aulani';
    @HostBinding('class.wdw') wdw: boolean = this.globalThemeService.getCampus() === 'wdw';
    constructor(
        public dataService: DataService,
        public globalThemeService: GlobalThemeService,
        public datepipe: DatePipe,
        public dateTimeZonePipe: DateTimeZonePipe,
        public router: Router,
        public routingService: RoutingService,
        public dscribeService: DScribeService,
        private el: ElementRef,
        private authService: AuthService
    ) {
      this.initializeConstants();
      this.campus = this.globalThemeService.getCampus();

    }

    private initializeConstants() {
        this.UI_CONSTANTS.rdHeading = rdConstants.rdHeading;
        this.UI_CONSTANTS.rdHeadingAltText = rdConstants.rdHeadingAltText;
        this.UI_CONSTANTS.rdNextButton = rdConstants.rdNextButton;
        this.UI_CONSTANTS.rdNextButtonAltText = rdConstants.rdNextButtonAltText;
        this.UI_CONSTANTS.rdCancelButton = rdConstants.rdCancelButton;
        this.UI_CONSTANTS.rdCancelButtonAltText = rdConstants.rdCancelButtonAltText;
        this.UI_CONSTANTS.rdConfirmationNumberText = rdConstants.rdConfirmationNumberText;
        this.UI_CONSTANTS.rdThroughText = rdConstants.rdThroughText;
    }

    ngOnInit() {
        if (this.campus === "wdw" && !this.authService.isInitialized.getValue()) {
          document.addEventListener("navigation", this.oneIdNavigationEventListener.bind(this));
        } else {
          this.loadData();
        }
        this.initializeNavUIFooter();
      }

  oneIdNavigationEventListener(event: any) {
    {
      if (event.detail.event === "oneId-init") {
        this.authService.initialize().then(() => {
          this.subscribeToInitialization();
        });
      } else if (event.detail.event === 'oneId-low-trust') {
        this.authService.reAuth().then(() => {
          console.info("oneId-low-trust on navigation", event);
        }).catch(() => {
          location.reload();
        });

      } else {
        console.info("Other one id event", event);
      }
    }
  }

  subscribeToInitialization() {
    this.authService.isInitialized
      .pipe(
        distinctUntilChanged(),
        filter(isInitialized => isInitialized === true)
      )
      .subscribe(() => {
        console.log('Authentication state changed to true, acting on it.');
        this.loadData();
      });
  }

  private initializeNavUIFooter() {
    const hostName = this.globalThemeService.getHostName() === 'localhost' ?
      'latest.disneyworld.disney.go.com' : this.globalThemeService.getHostName();
    this.wdwURL = rdConstants.protocol + hostName + rdConstants.footerURL;
    this.wrapperId = rdConstants.wrapperId;
    this.campus = this.globalThemeService.getCampus();
  }

    loadData(): void {
        this.getReservationSummaryData();
    }

    getReservationSummaryData() {
        this.loading = true;
        this.reservationSummarySub = this.dataService.getReservationSummaryData()
            .subscribe(
                (resSummary) => {
                    this.reservationSummary = resSummary;
                    if (this.reservationSummary.itemCount === 0) {
                        this.routingService.redirectToReturnURL();
                    } else if (this.reservationSummary.itemCount === 1) {
                        this.radioOption = this.reservationSummary.resortReservations[0].reservationNumber;
                        const routeURL = commonConstants.routeURLs.SLASH +
                              commonConstants.routeURLs.BASE_URL +
                            commonConstants.routeURLs.RESORT_RESERVATION + this.radioOption;
                        this.routingService.routeToRouteURL(routeURL);
                    } else {
                        this.radioOption = this.reservationSummary.resortReservations[0].reservationNumber;
                        this.displayReservationSelectionDetails = true;
                        this.getDScribeDetails(this.reservationSummary.resortReservations[0].facilityId);
                        setTimeout(() => {
                            this.el.nativeElement.querySelector('wdpr-radio.sign-radio:first-child')
                                .shadowRoot.querySelector('wdpr-radio-button').focus();
                        }, 2000);
                    }
                },
                (error) => {
                    this.loading = false;
                  if (!this.authService.isUnauthorizedError(error)) {
                    this.routeForError(error.error);
                  }

                }
            );
    }

    getDScribeDetails(facilityId) {
        this.dscribeSub = this.dscribeService.getSelectResStaticDetails(facilityId)
            .subscribe(
                (res) => {
                    this.loading = false;
                    this.dscribeDetails = _.filter(res.body.modules, function (module) {
                        return module.id === rdConstants.moduleId;
                    })[0].modules;
                    this.dscribeService.setSelectResStaticDetails(res);
                },
                (error) => {
                    this.loading = false;
                    if (!this.authService.isUnauthorizedError(error)) {
                      this.routeForError(error);
                    }
                }
            );
    }

    routeForError(error) {
        this.errorResponse = error;
        const routeURL = commonConstants.routeURLs.SLASH +
            commonConstants.routeURLs.BASE_URL +
            commonConstants.routeURLs.ERROR;
        this.routingService.routeToRouteURL(routeURL);
    }

    dateFormater(arrival, departure) {
        const formatedArrivalDate = this.datepipe.transform(arrival, 'EEEE, MMMM d, y');
        const formatedDepartureDate = this.datepipe.transform(departure, 'EEEE, MMMM d, y');
        return formatedArrivalDate + this.UI_CONSTANTS.rdThroughText + formatedDepartureDate;
    }

    ngOnDestroy(): void {
        if (this.reservationSummarySub) {
          this.reservationSummarySub.unsubscribe();
        }
        if (this.dscribeSub) {
            this.dscribeSub.unsubscribe();
        }
    }

    onRadioChange(e) {
        this.radioOption = e.detail.value;
    }

    routeToOLCIForm(reservationNo: number) {
        const routeURL = commonConstants.routeURLs.SLASH +
              commonConstants.routeURLs.BASE_URL +
            commonConstants.routeURLs.RESORT_RESERVATION + reservationNo;
        this.routingService.routeToRouteURL(routeURL);
    }

    redirectToReturnURL() {
        this.routingService.redirectToReturnURL();
    }

    getRadioAriaLabel(reservation, isSelected, i) {
        let selected = this.dscribeDetails[0].descriptions[0].sections.unselectedText + ', ';
        if (isSelected) {
            selected = this.dscribeDetails[0].descriptions[0].sections.selectedText + ', ';
        }
        return selected + reservation.facilityName + ' ' + this.UI_CONSTANTS.rdConfirmationNumberText + reservation.reservationNumber
            + ' ' + this.dateFormater(reservation.arrivalDateTime, reservation.departureDateTime)
            + ', ' + this.dscribeDetails[0].descriptions[0].sections.radioButtonText + ', ' + i +
            this.dscribeDetails[0].descriptions[0].sections.radioButtonAriaLabel +
            this.reservationSummary.itemCount;
    }

    getAulaniLogoAltText() {
        return rdConstants.aulaniLogo;
    }
}
