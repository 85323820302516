import { Component, Input, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DataService } from '../core/services/data/data.service';
import { Accommodation } from '@app/core/models/accommodation.model';
import { MdxAccountInfo } from '@app/core/models/mdx-account-info.model';
import { Address } from '@app/core/models/address.model';
import { CountryAndStateDetails } from '@app/core/models/country-and-state.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import * as raConstants from './reservation-address.component.constants';
import { ModalService } from '@app/shared/services/modal';
import { Dropdown } from '@app/core/models/dropdown.model';
import { ReservationService } from '@core/services/reservation.service';
import { ConfigService } from '@core/config.service';
import { TypeaheadService } from '@app/core/services/typeahead.service';
import { commonId } from 'app/core/constants/common-constants';
import { GuestReferences } from '../core/models/guest-references.model';

@Component({
    selector: 'dtr-hub-reservation-address',
    templateUrl: './reservation-address.component.html',
    styleUrls: ['./reservation-address.component.scss']
})
export class ReservationAddressComponent implements OnInit, OnDestroy {
    @Input() campus;
    @Input() collapsed;
    @Input() dscbDetails;
    @Input() isAddFlow;
    @Input() saveInProgress;
    resAddData: any;
    raModalData: any;
    edit = false;
    private _accommodation: Accommodation[];
    private _mdxAccountInfo: MdxAccountInfo;
    _addressForDisplay: Address;
    _addressCopyForDisplay: Address;
    countryAndStates: CountryAndStateDetails;
    countryAndStateSub: Subscription;
    UI_CONSTANTS: any = {};
    countryName: string;
    countryDropdown: Dropdown = {};
    stateDropdown: Dropdown = {};
    wdprSelectCountry: ElementRef;
    wdprSelectState: ElementRef;
    isSelectStateNotTouched = true;
    refreshComponents = false;
    zipcodeLabel = 'ZIP Code*';
    showStateTextBox = false;
    saveAddressToProfileCheckboxState = false;
    showModal = false;
    isInvalidInput = {};
    tempCheckboxState = false;
    config: any;
    commonData: any;

    @ViewChild('editBtn') editBtnElRef: ElementRef;
    @ViewChild('headingEl') headingElRef: ElementRef;
    @ViewChild('addressModal') addressModal: ElementRef;
    wdprAddressLineOne: ElementRef;
    wdprCity: ElementRef;
    wdprPostalCode: ElementRef;
    wdprSaveToProfileCheckBox: ElementRef;

    @ViewChild('wdprAddressLineOne') set addressLineOne(addressLineOne: ElementRef) {
        this.wdprAddressLineOne = addressLineOne;
    }

    @ViewChild('wdprCity') set city(city: ElementRef) {
        this.wdprCity = city;
    }

    @ViewChild('wdprPostalCode') set postalCode(postalCode: ElementRef) {
        this.wdprPostalCode = postalCode;
    }

    @ViewChild('wdprSelectCountry') set content1(content1: ElementRef) {
        this.wdprSelectCountry = content1;
        this.addListenerToCountryDropdown();
    }

    @ViewChild('wdprSelectState') set content2(content1: ElementRef) {
        this.wdprSelectState = content1;
        this.addListenerToStateDropdown();
    }

    @ViewChild('wdprSaveToProfileCheckBox') set content3(content1: ElementRef) {
        this.wdprSaveToProfileCheckBox = content1;
    }

    @Input() set accommodation(value) {
        this._accommodation = value;
    }

    @Input() set mdxAccountInfo(value) {
        this._mdxAccountInfo = value;
    }

    constructor(public dataService: DataService,
        private modalService: ModalService,
        private renderer: Renderer2,
        public reservationService: ReservationService,
        public configService: ConfigService,
        public typeaheadService: TypeaheadService) {
        this.config = this.configService.config;
        this.initializeConstants();
    }

    private initializeConstants() {
        this.UI_CONSTANTS = raConstants;
    }

    ngOnInit() {
        const primaryGuest = this.getPrimaryGuest(this._accommodation);
        this.reservationService.roomcheckinPayload.address = {
            'swid': this._mdxAccountInfo.swid,
            'guestId': primaryGuest.guestId,
            'partyId': primaryGuest.partyId,
            'clientId': this.config.xAppIdHeader[this.campus]
        };
        this._addressForDisplay = primaryGuest.primaryAddress
            && primaryGuest.primaryAddress.addressLineOne ?
            Address.fromJSON(primaryGuest.primaryAddress)
            : Address.fromJSON(this._mdxAccountInfo.address);
        this.reservationService.setAddressIsValid(this.isAddressValid());
        this.updateRoomcheckinAddress(this._addressForDisplay);
        this.getCountryAndStateData();
        this.resAddData = _.find(this.dscbDetails, function(module) {
            return module.id === raConstants.moduleId;
        });
        this.raModalData = _.find(this.dscbDetails, function(module) {
            return module.id === raConstants.raModalId;
        });
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
        this.saveInProgress = false;
    }

    getCountryAndStateData() {
        this.countryAndStateSub = this.dataService.getCountryAndStateData()
            .subscribe(
                (res) => {
                    this.countryAndStates = res;
                    const addressForDisplayCountry = this._addressForDisplay.country;
                    const selCountry = this.getCountryFromCode(addressForDisplayCountry);
                    this.countryName = selCountry.name;

                    this.initializeModalValues();
                },
                (error) => console.log(error.error)
            );
    }

    getPrimaryGuest(accommodation: Accommodation[]) {
        const guestReferences = [];
        _.forEach(accommodation, function (accommodationDetail) {
            _.forEach(accommodationDetail.guestReferences, function (guestReference) {
                guestReferences.push(guestReference);
            });
        });
        return _.find(guestReferences, guestReference => guestReference.guest.primaryGuest === true).guest;
    }

    ngOnDestroy(): void {
        this.countryAndStateSub.unsubscribe();
    }

    get addressForDisplay(): Address {
        return this._addressForDisplay;
    }

    get addressCopyForDisplay(): Address {
        return this._addressCopyForDisplay;
    }

    openModal() {
        this.initializeModalValues();
        this.modalService.open('address-modal');
    }

    closeModal() {
        this.modalService.close('address-modal');
        this.showModal = false;
        this.editBtnElRef.nativeElement.focus();
    }

    focusHeadingElement() {
        this.headingElRef.nativeElement.focus();
    }

    initializeModalValues() {
        this._addressCopyForDisplay = new Address(
            this._addressForDisplay.addressLineOne,
            this._addressForDisplay.addressLineTwo,
            this._addressForDisplay.city,
            this._addressForDisplay.country,
            this._addressForDisplay.postalCode,
            this._addressForDisplay.state
        );
        const addressForDisplayCountry = this._addressForDisplay.country;
        const selCountry = this.getCountryFromCode(addressForDisplayCountry);

        this.countryName = selCountry.name;

        this.countryDropdown.options =
            _.reduce(this.countryAndStates.countries, (arr, value, key) => {
                arr.push({
                    key: value.isoCountryCode2,
                    value: value.name
                });
                return arr;
            }, []);
        this.countryDropdown.value = selCountry.isoCountryCode2;
        this._addressCopyForDisplay.country = selCountry.isoCountryCode2;
        this.onCountrySelect(this.countryName, this._addressForDisplay.state,
            selCountry.isoCountryCode2, true);
    }

    addListenerToStateDropdown() {
        if (_.isUndefined(this.wdprSelectState)) {
            this.addRemoveListener(this.stateDropdown);
            return;
        }
        this.stateDropdown.listener =
            this.renderer.listen(this.wdprSelectState.nativeElement, 'item-selected',
                (evt) => {
                    this.onStateSelect(evt);
                });
    }

    addListenerToCountryDropdown() {
        if (_.isUndefined(this.wdprSelectCountry)) {
            this.addRemoveListener(this.countryDropdown);
            return;
        }
        this.countryDropdown.listener =
            this.renderer.listen(this.wdprSelectCountry.nativeElement, 'item-selected',
                (evt) => {
                    this.onCountrySelect(evt.detail.value, undefined, evt.detail.key, false);
                });
    }

    onStateSelect(e) {
        this.isSelectStateNotTouched = false;
        this._addressCopyForDisplay.state = e.target.value;
        this.isInvalidInput['state'] = this.isInvalidStateSelect();
        this.stateDropdown.ariaLabel = this.getStateSelectAriaLabel();
    }

    onCountrySelect(selOptionValue: string, showPreselectedValue: string,
        selOptionKey: string, initializeModal: Boolean) {
        this.isSelectStateNotTouched = true;
        _.each(this.isInvalidInput, (value, key) => {
            this.isInvalidInput[key] = false;
        });
        if (!initializeModal) {
            delete this.stateDropdown.value;
            this._addressCopyForDisplay.addressLineOne = '';
            this._addressCopyForDisplay.addressLineTwo = '';
            this._addressCopyForDisplay.city = '';
            this._addressCopyForDisplay.state = '';
            this._addressCopyForDisplay.postalCode = '';
        }
        this._addressCopyForDisplay.country = selOptionKey;
        this.countryDropdown.ariaLabel = this.getCountrySelectAriaLabel();
        const stateOptionArr = this.countryAndStates.states[
            selOptionValue === 'United States' ? 'us' : 'ca'
        ];
        this.stateDropdown.options =
            _.reduce(stateOptionArr, (arr, value, key) => {
                arr.push({
                    key: value.stateOrProvince,
                    value: value.name
                });
                return arr;
            }, []);
        this.updateStateZipCodeLabels(selOptionValue, showPreselectedValue);
    }
    addRemoveListener(dropdown: Dropdown) {
        if (dropdown.listener) {
            dropdown.listener();
            dropdown.listener = undefined;
        }
    }

    selCountryUnitedStates() {
        this.stateDropdown.label = this.raModalData.descriptions[1].sections.state;
        this.zipcodeLabel = this.raModalData.descriptions[1].sections.zip;
    }

    selCountryCanada() {
        this.stateDropdown.label = this.raModalData.descriptions[1].sections.province;
        this.zipcodeLabel = this.raModalData.descriptions[1].sections.postalCodeLabel;
    }

    selCountryUnitedKingdom() {
        this.stateDropdown.label = this.raModalData.descriptions[1].sections.county;
        this.zipcodeLabel = this.raModalData.descriptions[1].sections.postalCodeLabel;
        this.showStateTextBox = true;
    }

    selCountryOthers() {
        this.stateDropdown.label = this.raModalData.descriptions[1].sections.territory;
        this.zipcodeLabel = this.raModalData.descriptions[1].sections.postalCodeLabel;
        this.showStateTextBox = true;
    }

    updateStateZipCodeLabels(selectedCountryValue, showPreselectedValue?: string) {
        this.showStateTextBox = false;
        if (selectedCountryValue === 'United States') {
            this.selCountryUnitedStates();
        } else if (selectedCountryValue === 'Canada') {
            this.selCountryCanada();
        } else if (selectedCountryValue === 'United Kingdom') {
            this.selCountryUnitedKingdom();
        } else {
            this.selCountryOthers();
        }
        this.stateDropdown.value = showPreselectedValue;

        this.stateDropdown.ariaLabel = this.getStateSelectAriaLabel();
        this.refreshComponents = true;
        setTimeout(() => { this.refreshComponents = false; }, 1);

    }

    get checkboxAria() {
        let ariaLabel =  this.tempCheckboxState ? this.raModalData.descriptions[1]
                        .sections.checkedState : this.raModalData.descriptions[1].sections.uncheckedState ;
        ariaLabel +=  ' ' + this.raModalData.descriptions[1].sections.saveAddressToProfileHeadingText + '. ' ;
        ariaLabel += this.raModalData.descriptions[1].sections.saveAddressToProfileSubText;
        ariaLabel += this.raModalData.sections.footerAccesability;
        return ariaLabel;
    }

    getStateSelectAriaLabel() {
        let dropdownAriaLabel = `${this.stateDropdown.label}`;
        dropdownAriaLabel += `, ${this.raModalData.sections.requiredFieldText}`;
        dropdownAriaLabel += `, ${this.raModalData.descriptions[1].sections.dropdownText}`;
        if (this._addressCopyForDisplay.state && this._addressCopyForDisplay.state !== '') {
            const selectedIndex =
                _.findIndex(this.stateDropdown.options, (option) => {
                    return this._addressCopyForDisplay.state === option.key;
                });
            dropdownAriaLabel += `, ${this._addressCopyForDisplay.state}`;
            dropdownAriaLabel += `, ${selectedIndex} ${this.raModalData.sections.body} ${this.stateDropdown.options.length - 1}`;
        }
        if (this.isInvalidInput['state']) {
            dropdownAriaLabel += ', ' + this.raModalData.descriptions[0].sections.enterError + this.stateDropdown.label;
        }
        return dropdownAriaLabel;
    }

    getCountrySelectAriaLabel() {
        let dropdownAriaLabel = `${this.raModalData.descriptions[1].sections.country}`;
        dropdownAriaLabel += `, ${this.raModalData.sections.requiredFieldText}`;
        dropdownAriaLabel += `, ${this.raModalData.descriptions[1].sections.dropdownText}`;
        dropdownAriaLabel += `, ${this._addressCopyForDisplay.country}`;
        const selectedIndex =
            _.findIndex(this.countryDropdown.options, (option) => {
                return this._addressCopyForDisplay.country === option.key;
            });
        dropdownAriaLabel += `, ${selectedIndex} ${this.raModalData.sections.body} ${this.countryDropdown.options.length - 1}`;
        return dropdownAriaLabel;
    }

    onAddressLineOneChange(e, fieldName, propertyName) {
        this._addressCopyForDisplay.addressLineOne = e.target.value;
        this.isInvalidInput[propertyName] = this.isInvalid(fieldName, propertyName);
    }

    onAddressLineTwoChange(e) {
        this._addressCopyForDisplay.addressLineTwo = e.target.value;
    }

    onStateChange(e) {
        this.isSelectStateNotTouched = false;
        this._addressCopyForDisplay.state = e.target.value;
    }

    onCityChange(e, fieldName, propertyName) {
        this._addressCopyForDisplay.city = e.target.value;
        this.isInvalidInput[propertyName] = this.isInvalid(fieldName, propertyName);
    }

    onPostalCodeChange(e, fieldName, propertyName) {
        this._addressCopyForDisplay.postalCode = e.target.value;
        this.isInvalidInput[propertyName] = this.isInvalid(fieldName, propertyName);
    }

    isInvalid(fieldName: string, propertyName: string,
        checkTouchedProp?: Boolean) {
        let isInValidProp = false;
        if (this[fieldName]) {
            const element = this[fieldName].nativeElement;
            isInValidProp = this._addressCopyForDisplay[propertyName] === '' &&
                (_.isUndefined(checkTouchedProp) || checkTouchedProp ?
                    element.classList.contains('touched') : true);
            if (!isInValidProp && 'postalCode' === propertyName) {
                isInValidProp = !this.validatePostalCode();
            }
        }

        return isInValidProp;
    }

    isInvalidStateSelect() {
        return !this.isSelectStateNotTouched &&
            (
                this._addressCopyForDisplay.state === '' ||
                _.isUndefined(this._addressCopyForDisplay.state)
            );
    }

    validatePostalCode() {
        return this._validatePostalCode(this._addressCopyForDisplay);
    }

    _validatePostalCode(address: Address) {
        let regex = '';
        switch (address.country) {
            case 'US':
                regex = '^([0-9]{5})$';
                break;
            case 'CA':
                regex = '^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\\d{1}[A-Za-z]{1} {0,1}\\d{1}[A-Za-z]{1}\\d{1}$';
                break;
            case 'GB':
                regex = '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?';
                regex += '[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr';
                regex += '-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) ';
                regex += '{0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$';
                break;
            default:
                regex = '^[0-9A-Za-z\\-\\s]{1,10}$';
                break;
        }
        const regexExpression = new RegExp(regex);
        return regexExpression.test(address.postalCode);
    }

    validateAndUpdate() {
        this.isInvalidInput['addressLineOne'] =
            this.isInvalid('wdprAddressLineOne', 'addressLineOne', false);
        this.isInvalidInput['city'] =
            this.isInvalid('wdprCity', 'city', false);
        this.isInvalidInput['postalCode'] =
            this.isInvalid('wdprPostalCode', 'postalCode', false);
        if (!this.showStateTextBox) {
            this.isSelectStateNotTouched = false;
            this.isInvalidInput['state'] =
                this.isInvalidStateSelect();
        }
        const values = _.keys(_.invert(this.isInvalidInput));
        if (_.some(values, function (value) { return value === 'true'; })) {
            return false;
        }
        _.extend(this._addressForDisplay, this._addressCopyForDisplay);
        this.countryName = this.getCountryFromCode(
            this._addressCopyForDisplay.country
        ).name;
        this.saveAddressToProfileCheckboxState = this.tempCheckboxState;
        this.updateRoomcheckinAddress(this._addressForDisplay);
        this.reservationService.setAddressIsValid(this.isAddressValid());
        this.closeModal();
    }

    getCountryFromCode(countryCode) {
        const filteredCountry = _.filter(this.countryAndStates.countries, function (country) {
            return countryCode === country.isoCountryCode3 ||
                countryCode === country.isoCountryCode2;
        });

        const defaultCountry = {
            'isoCountryCode2': 'US',
            'isoCountryCode3': 'USA',
            'name': 'United States'
        };

        return filteredCountry && filteredCountry.length > 0 ? filteredCountry[0] : defaultCountry;
    }

    onCheckboxStateChanged(event) {
        this.tempCheckboxState = event.detail.value;
    }

    updateRoomcheckinAddress(address) {
        const roomcheckinPayload = this.reservationService.roomcheckinPayload.address;
        roomcheckinPayload.addressLineOne = address.addressLineOne;
        if (address.addressLineTwo && address.addressLineTwo.trim()) {
            roomcheckinPayload.addressLineTwo = address.addressLineTwo.trim();
        } else {
            roomcheckinPayload.addressLineTwo = '';
        }
        roomcheckinPayload.city = address.city;
        roomcheckinPayload.state = address.state;
        roomcheckinPayload.country = address.country || 'USA';
        roomcheckinPayload.postalCode = address.postalCode;
        roomcheckinPayload.saveToProfile = this.saveAddressToProfileCheckboxState;
    }

    onKeyup(e, list, dropdownValue) {
        if (e.target.className === 'state') {
            const newValue = this.typeaheadService.focusOnNextMatch(e, list, dropdownValue.value, this.wdprSelectState);
            if (newValue) {
                dropdownValue.value = newValue;
            }
        }
        if (e.target.className === 'country') {
            const newValue = this.typeaheadService.focusOnNextMatch(e, list, dropdownValue.value, this.wdprSelectCountry);
            if (newValue) {
                dropdownValue.value = newValue;
            }
        }
    }

    isAddressValid(): boolean {
        return this.reservationService.isAddressValid(this._addressForDisplay);
    }
}
