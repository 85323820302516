import { Component, OnInit, Input } from '@angular/core';
import * as tncConstants from './terms-condition.component.constants';
import { RoutingService } from '@core/services/routing.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { ReservationService } from '../core/services/reservation.service';
import * as _ from 'lodash';
import { DScribeService } from '@app/core/services/dscribe.service';
import { sourceProcess } from '@app/pet-registration/pet-registration.constants';

@Component({
    selector: 'dtr-hub-terms-condition',
    templateUrl: './terms-condition.component.html',
    styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
    @Input() campus;
    @Input() dscbDetails;
    @Input() saveInProgress;
    UI_CONSTANTS: any = {};
    agreeCheckboxState = false;
    checkboxState = 'unchecked';
    tncChecked = false;
    tncData: any;

    petAgreeCheckboxState = false;
    petCheckboxState = 'unchecked';
    petTncChecked = false;
    petTncData: any;

    constructor(public routingService: RoutingService,
        public dscribeService: DScribeService,
        public reservationService: ReservationService,
    ) {
        this.initializeConstants();
    }
    private initializeConstants() {
        this.UI_CONSTANTS.tncHeading = tncConstants.tncHeading;
        this.UI_CONSTANTS.tncHeadingAlt = tncConstants.tncHeadingAlt;
        this.UI_CONSTANTS.tncAgreeTxt = tncConstants.tncAgreeTxt;
        this.UI_CONSTANTS.tncAgreeDesc = tncConstants.tncAgreeDesc;
        this.UI_CONSTANTS.tncLink = tncConstants.tncLink;
        this.UI_CONSTANTS.tncLinkAlt = tncConstants.tncLinkAlt;
    }

    ngOnInit() {

        this.reservationService.setTnCSaveSet(false);
        this.reservationService.setPetTnCSaveSet(false);
        this.tncData = _.find(this.dscbDetails, function (module) {
            return module.id === tncConstants.moduleId;
        });

        const petData = _.find(this.dscbDetails, function (module) {
            return module.id === tncConstants.moduleIdPet;
        });

        this.petTncData = _.find(petData.descriptions, function (module) {
            return module.id === tncConstants.moduleIdPetTNC;
        });

    }

    onPetCheckBoxChanged(e) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString();
        this.petAgreeCheckboxState = e.detail.value;
        this.reservationService.setPetTnCSaveSet(this.petAgreeCheckboxState);
        this.petTncChecked = this.petAgreeCheckboxState;
        this.reservationService.roomcheckinPayload.preferences.petInfo.policyAcknowledge = formattedDate;
        this.reservationService.roomcheckinPayload.preferences.petInfo.sourceProcess = sourceProcess;
    }

    getPetAgreeAriaLabel() {
        this.petCheckboxState = this.petAgreeCheckboxState ?
            this.petTncData.sections.checkboxAccessibilityChecked :
            this.petTncData.sections.checkboxAccessibilityUnChecked;
        return this.petCheckboxState + ', ' + this.petTncData.sections.accept +
            this.petTncData.sections.checkboxText +
            this.petTncData.sections.footer;
    }

    onCheckBoxChanged(e) {
        this.agreeCheckboxState = e.detail.value;
        this.reservationService.setTnCSaveSet(this.agreeCheckboxState);
        this.tncChecked = this.agreeCheckboxState;
    }

    getAgreeAriaLabel() {
        this.checkboxState = this.agreeCheckboxState ?
            this.tncData.descriptions[0].sections.checkboxAccessibilityChecked :
            this.tncData.descriptions[0].sections.checkboxAccessibilityUnChecked;
        return this.checkboxState + ', ' + this.tncData.descriptions[0].sections.accept +
            this.tncData.descriptions[0].sections.checkboxText +
            this.tncData.descriptions[0].sections.footer;
    }

    redirectToLinkForPet() {
        const dscribeSub = this.dscribeService.getOlciStaticDetails(this.reservationService.facilityId)
            .subscribe(
                (res) => {
                    const dscribeDetails = _.filter(res.body.modules, function (module) {
                        return module.id === tncConstants.moduleIdTNC;
                    })[0].modules;

                    localStorage.setItem(commonConstants.olciPetTncLocalStorageName,
                        this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsBody);
                    localStorage.setItem(commonConstants.olciPetTncSubTitleLocalStorageName,
                        this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsSubTitle);
                    localStorage.setItem(commonConstants.olciPetTncTitleLocalStorageName,
                        this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsTitle);
                    localStorage.setItem(commonConstants.olciTncStaticContentLocalStorageName,
                        JSON.stringify(dscribeDetails));
                    const routeURL = commonConstants.petRouteURLs.BASE_URL +
                        commonConstants.petRouteURLs.PET_TNC;
                    this.routingService.routeToRouteURL(routeURL, true);
                    return false;
                },
                (error) => {
                    console.log('error: ', error);
                }
            );
        return false;

    }

    redirectToLink() {
        const dscribeSub = this.dscribeService.getOlciStaticDetails(null)
            .subscribe(
                (res) => {
                    const dscribeDetails = _.filter(res.body.modules, function (module) {
                        return module.id === tncConstants.moduleIdTNC;
                    })[0].modules;
                    localStorage.setItem(commonConstants.olciTncLocalStorageName,
                        this.reservationService.roomcheckinPayload.generalInfo.termsConditions);
                    localStorage.setItem(commonConstants.olciTncStaticContentLocalStorageName,
                        JSON.stringify(dscribeDetails));
                    const routeURL = commonConstants.routeURLs.BASE_URL +
                        commonConstants.routeURLs.RRN_TNC;
                    this.routingService.routeToRouteURL(routeURL, true);
                    return false;
                },
                (error) => {
                    console.log('error: ', error);
                }
            );
        return false;

    }
}
