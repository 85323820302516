import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as constants from './resort-arrival.component.constants';
import { GeneralInfo } from '@app/core/models/general-info.model';
import { PrearrivalInfo } from '@app/core/models/pre-arrival-info.model';
import { Accommodation } from '@app/core/models/accommodation.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReservationService } from '@core/services/reservation.service';
import { TypeaheadService } from '@app/core/services/typeahead.service';
import { commonId } from 'app/core/constants/common-constants';

@Component({
    selector: 'dtr-hub-resort-arrival',
    templateUrl: './resort-arrival.component.html',
    styleUrls: ['./resort-arrival.component.scss']
})
export class ResortArrivalComponent implements OnInit {
    @Input() campus;
    @Input() collapsed;
    @Input() isAddFlow;
    @Input() dscbDetails;
    @Input() saveInProgress;
    resortArrivalData: any;
    edit = false;
    showResortError = false;
    wdprSelectResortArrReq0: ElementRef;
    commonData: any;
    arrivalTimeErrorMsg: string[];
    private _generalInfo: GeneralInfo;
    private _preArrivalInfo: PrearrivalInfo;
    private _accommodation: Accommodation;

    constructor(public reservationService: ReservationService, public typeaheadService: TypeaheadService) {}

    @Input() set generalInfo(value) {
        this._generalInfo = value;
    }

    @Input() set preArrivalInfo(value) {
        this._preArrivalInfo = value;
    }

    @Input() set accommodation(value) {
        this._accommodation = value;
    }

    @ViewChild('wdprSelectResortArrReq0') set content0(content0: ElementRef) {
        this.wdprSelectResortArrReq0 = content0;
    }

    _resortArrivalTimes: any[];
    _arrivalDate: string;
    _arrivalDateNdme: string;
    _selectedArrivalTime: string;
    _selectedArrivalTimeForDisplay: string;

    ngOnInit() {
        this.resortArrivalData = _.find(this.dscbDetails, function(module) {
            return module.id === constants.moduleId;
        });
        this._resortArrivalTimes = this.resortArrivalData.descriptions[0].sections.arrivalTimeRange;

        this._arrivalDate = moment(this._accommodation.period.startDate).format('MMM D, YYYY');
        this._arrivalDateNdme = moment(this._accommodation.period.startDate).format('dddd MMM D, YYYY');
        if (!this._preArrivalInfo.showOlciEta) {
            // Don't change or modify anything about the arrival time, BUT,
            // act as if Resort Arrival Time has been set when it's a res with DME component
            // Also applies for Real Time OLCI flow where showOlciEta is false
            this.reservationService.setRatSaveSet(true);
        } else if ( this._preArrivalInfo.arrivalDepartureInfo.arrivalTime &&
                    this._preArrivalInfo.arrivalDepartureInfo.arrivalTime !== '00:00:00') {
            this._selectedArrivalTime = this._preArrivalInfo.arrivalDepartureInfo.arrivalTime;
            this.reservationService.setRatSaveSet(true);
        } else {
            this._selectedArrivalTime = '';
            this.reservationService.setRatSaveSet(false);
            this.showResortError = true;
            this.arrivalTimeErrorMsg = [this.resortArrivalData.descriptions[1].sections.arrivalTimeRequired];
        }
        this._selectedArrivalTimeForDisplay = this.timeForDisplay(this._selectedArrivalTime);
        const payload = this.reservationService.roomcheckinPayload;
        payload.prearrivalInfo.arrivalDepartureInfo.arrivalTime = this._selectedArrivalTime;
        payload.prearrivalInfo.arrivalDepartureInfo.departureTime = this._preArrivalInfo.arrivalDepartureInfo.departureTime
            ? this._preArrivalInfo.arrivalDepartureInfo.departureTime
            : this._generalInfo.standardCheckOutTime;

        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
    }

    get resortArrivalTime(): any[] {
        return this._resortArrivalTimes;
    }

    get selectedArrivalTimeForDisplay(): string {
        return this._selectedArrivalTimeForDisplay;
    }

    get arrivalDate(): string {
        return this.resortArrivalData.sections.description.replace(constants.dscribeArrivalDateRef, this._arrivalDate) + '*';
    }

    get arrivalDateNdme(): string {
        return this._arrivalDateNdme;
    }

    get selectedArrivalTime(): string {
        return this._selectedArrivalTime;
    }

    get getResortArrivalHeading(): string {
        return this.resortArrivalData.sections.titleAccesability;
    }

    get getResortArrivalHeadingText(): string {
        return this.resortArrivalData.sections.title;
    }

    get getResortArrivalDropdownText(): string {
        const selectedArrivalTimeForText = this.timeForDisplay(this._selectedArrivalTime);
        return (
            this.resortArrivalData.sections.bodyArrival +
            this._arrivalDate +
            this.resortArrivalData.descriptions[0].arrivalFieldAccessibility +
            selectedArrivalTimeForText +
            ', ' +
            this.getSelectedIndex(this._selectedArrivalTime) +
            this.resortArrivalData.descriptions[0].sections.body +
            this._resortArrivalTimes.length
        );
    }

    private getSelectedIndex(selectedItem) {
        const index = _.findIndex(this._resortArrivalTimes, time => {
            return time.key === selectedItem;
        });
        return index;
    }

    get getResortArrivalDefaultText(): string {
        const displayStandardCheckInTime =
            this.timeForDisplay(this._generalInfo.standardCheckInTime);

        return this.resortArrivalData.sections.additionalInformationDetail.replace(
            constants.dscribeResortArrivalTimeRef,
            displayStandardCheckInTime
        );
    }

    get getSavedArrivalTime(): string {
        return this.selectedArrivalTimeForDisplay + ' ' + this.arrivalDateNdme;
    }

    get getDmeText(): string {
        return this.resortArrivalData.sections.bodyDME;
    }

    get resortArrivalEdit(): string {
        return this.commonData.descriptions[0].sections.editLabel;
    }

    get resortArrivalEditText(): string {
        return this.commonData.descriptions[0].sections.editLabel;
    }

    timeForDisplay(value): string {
        if (value === '') {
            return value;
        }
        const arrivalTimeDetails: string = value;
        const filteredTime = _.filter(this._resortArrivalTimes, function(arrivalTime) {
            return arrivalTimeDetails === arrivalTime.key;
        });

        return filteredTime && filteredTime.length > 0 ? filteredTime[0].value : this._resortArrivalTimes[0].value;
    }

    valueChanged(newTime) {
        if (newTime) {
            this._selectedArrivalTime = newTime;
            const payload = this.reservationService.roomcheckinPayload;
            payload.prearrivalInfo.arrivalDepartureInfo.arrivalTime = newTime;
            this.reservationService.setRatSaveSet(true);
            this.showResortError = false;
        }
    }

    onKeyup(e, list, dropdownValue) {
        const newValue = this.typeaheadService.focusOnNextMatch(e, list, dropdownValue, this.wdprSelectResortArrReq0);
        if (newValue) {
            this._selectedArrivalTime = newValue;
            this.reservationService.setRatSaveSet(true);
            this.showResortError = false;
        }
    }
}
