import { Injectable } from '@angular/core';
import { Accommodation, Period } from '@app/core/models/accommodation.model';
import { Reservation } from '../models/reservation.model';
import { Guest } from '../models/guest.model';
import * as _ from 'lodash';
import { MdxAccountInfo } from '../models/mdx-account-info.model';
import { GlobalThemeService } from '../../services/global-theme-service/global.theme.service';
import * as moment from 'moment-timezone';
import * as commonConstants from 'app/core/constants/common-constants';
import {Address} from '@core/models/address.model';
import { CacheDetails } from '../models/cache-details.model';

@Injectable()
export class ReservationService {

    _roomcheckinPayload: any = {};
    rrnSaveError: boolean;
    hacSaveError: boolean;
    hapSaveError: boolean;
    tncSaveError: boolean;
    PetTncSaveError: boolean;
    ratSaveError: boolean;
    paymentSheetLoaded: boolean;
    sessionToken: string;
    private _addressIsValid: boolean;

    _facilityId: number;
    _petCount: number;

    constructor(public globalThemeService: GlobalThemeService) { }

    getResortName(accommodations: Accommodation[]): string {
        let resortName = '';
        if (accommodations && accommodations.length) {
            const accommodation = accommodations[0];
            resortName = accommodation.facilityName;
        }
        return resortName;
    }

    getRoomType(accommodations: Accommodation[]): string {
        let roomType = '';
        if (accommodations && accommodations.length > 0) {
            roomType = accommodations[0].resortRoomTypeDescription;
            roomType = roomType.replace(accommodations[0].facilityName + '-', '');
        }
        return roomType;
    }

    getPeriodOfStay(accommodations: Accommodation[]): Period[] {
        const periodMap = new Map<string, Period>();
        if (accommodations && accommodations.length) {

            for (const accommodation of accommodations) {
                const period = {
                    startDate: moment(accommodation.period.startDate).format(commonConstants.moment.reservationDatesFormat),
                    endDate: moment(accommodation.period.endDate).format(commonConstants.moment.reservationDatesFormat)
                };
                periodMap.set(accommodation.period.startDate, period);
            }
        }
        return Array.from(periodMap.values());
    }

    isSYWEligible(reservation: Reservation): boolean {
        let isSYWEligible = true;
        if (reservation.travelPlanSegmentDetails.serviceYourWayDetails.eligibility === false) {
            isSYWEligible = false;
        }
        return isSYWEligible;
    }

    isSYWSet(reservation: Reservation): boolean {
        let isSYWSet;
        if (reservation.travelPlanSegmentDetails.serviceYourWayDetails &&
            reservation.prearrivalInfo.serviceYourWayOptIn !== null) {
                isSYWSet = true;
            } else {
                isSYWSet = false;
        }
        return isSYWSet;
    }

    isRRNSet(reservation: Reservation): boolean {
        let isRRNSet;
        if (reservation.prearrivalInfo.emailAddress || reservation.prearrivalInfo.mobilePhoneNumber) {
            isRRNSet = true;
        } else {
            isRRNSet = false;
        }
        return isRRNSet;
    }

    isResortArrivalSet(reservation: Reservation): boolean {
        if ((reservation.prearrivalInfo.arrivalDepartureInfo.arrivalTime &&
            reservation.prearrivalInfo.arrivalDepartureInfo.arrivalTime !== '00:00:00')) {
            return true;
        }
        return false;
    }

    isMagicBandSet(reservation: Reservation): boolean {
        let isMagicBandSet;
        if (!reservation.travelPlanSegmentBands ||
            (reservation.travelPlanSegmentBands['optOutAvailable'] && !reservation.travelPlanSegmentBands['optOut'])) {
            isMagicBandSet = false;
        } else {
            isMagicBandSet = true;
        }
        return isMagicBandSet;
    }

    isPinSet(reservation: Reservation): boolean {
        let isPinSet;
        const guestsForPIN: Guest[] = _.filter(reservation.travelPlanSegmentDetails['accommodation'][0]['guestReferences'],
            function (guest) {
                return 2 < guest.age;
        });
        const guestWithoutPIN: Guest[] = _.filter(reservation.chargeAccountDetails.guests, function (guest) {
            const findGuestForPIN: Guest[] = _.filter(guestsForPIN, function (guestForPin) {
                return guestForPin.guest.guestIdReferences.value === guest.externalReference.referenceValue;
            });
            return guest.pinAvailable === false && findGuestForPIN.length > 0;
        });
        if (reservation && reservation.chargeAccountDetails.guests
            && reservation.chargeAccountDetails.guests.length > 0 && guestWithoutPIN.length <= 0) {
            isPinSet = true;
        } else {
            isPinSet = false;
        }
        return isPinSet;
    }

    isPetRegistrationSet(reservation: Reservation): boolean {
        let isPetRegistered = false;
        const preferences = reservation.preferences;

        if (preferences && preferences.petInfo) {
            const { petInfo } = preferences;
            if (
                petInfo.petCount > 0 &&
                (petInfo.sourceProcess ?? false) &&
                (petInfo.policyAcknowledge ?? false)
            ) {
                isPetRegistered = true;
            }
        }

        return isPetRegistered;
    }

    isHotelAccountChargesSet(reservation: Reservation): boolean {
        let isCardDetailsPresent = false;

        if (reservation.settlementMethods && reservation.settlementMethods.card && reservation.settlementMethods.card.number) {
            isCardDetailsPresent = true;
        }

        return isCardDetailsPresent;
    }

    getAPPEstablishSessionRequest(
        mdxAccountInfo: MdxAccountInfo,
        accommodation: Accommodation[],
        travelPlanSegmentId: string): any {
        const establishSessionReq = {
            'reservationDetails': {
                'reservationNumber': '',
                'arrivalDate': '',
                'departureDate': '',
                'resortCode': '',
                'location': '',
                'requestedDate': '',
                'requestedTime': '',
                'requestedTimeZone': ''
            },
            'userProfile': {
                'name': {
                    'firstName': '',
                    'lastName': ''
                },
                'address': [{
                    'addressLineOne': '',
                    'addressLineTwo': '',
                    'city': '',
                    'state': '',
                    'country': '',
                    'zip': '',
                    'province': ''
                }],
                'emailAddress': ''
            },
            'guestDetails': [],
            'domain': '',
            'localTime': ''
        };

        let domain = this.globalThemeService.getHostName();
        if (this.globalThemeService.getHostPort()) {
            domain = domain + ':' + this.globalThemeService.getHostPort();
        }
        establishSessionReq.domain = 'https://' + domain;
        establishSessionReq.localTime = moment().format(commonConstants.moment.dateTimeFormat);
        establishSessionReq.userProfile.emailAddress = mdxAccountInfo.email;

        establishSessionReq.reservationDetails.reservationNumber = travelPlanSegmentId.toString();
        establishSessionReq.reservationDetails.location = this.globalThemeService.getCampus() === 'wdw' ? 'WDW' : 'AUL';
        establishSessionReq.reservationDetails.requestedDate = moment().format(commonConstants.moment.dateFormat);
        establishSessionReq.reservationDetails.requestedTime = moment().format(commonConstants.moment.timeFormat);
        establishSessionReq.reservationDetails.requestedTimeZone = moment().tz(moment.tz.guess()).format('z');

        establishSessionReq.reservationDetails.arrivalDate = moment(accommodation[0].period.startDate)
            .format(commonConstants.moment.reservationDateFormat);
        establishSessionReq.reservationDetails.departureDate = moment(accommodation[0].period.endDate)
            .format(commonConstants.moment.reservationDateFormat);
        establishSessionReq.reservationDetails.resortCode = accommodation[0].resortCode;
        const address = mdxAccountInfo.address;
        establishSessionReq.userProfile.address[0].addressLineOne = address.addressLineOne;
        if (address.addressLineTwo) {
            establishSessionReq.userProfile.address[0].addressLineTwo = address.addressLineTwo;
        }
        establishSessionReq.userProfile.address[0].city = address.city;
        establishSessionReq.userProfile.address[0].state = address.state;
        establishSessionReq.userProfile.address[0].country = address.country;
        establishSessionReq.userProfile.address[0].zip = address.postalCode;
        establishSessionReq.userProfile.address[0].province = address.state;

        _.forEach(accommodation, function (accomdationDetail) {
            _.forEach(accomdationDetail.guestReferences, function(guestDetail) {
                if (guestDetail.guest.primaryGuest) {
                    establishSessionReq.userProfile.name.firstName = guestDetail.guest.firstName;
                    establishSessionReq.userProfile.name.lastName = guestDetail.guest.lastName;
                }

                const guest = {
                    'firstName': guestDetail.guest.firstName,
                    'lastName': guestDetail.guest.lastName,
                    'age': guestDetail.age
                };
                establishSessionReq.guestDetails.push(guest);
            });
        });

        return establishSessionReq;
    }

    get roomcheckinPayload() {
        return this._roomcheckinPayload;
    }

    set roomcheckinPayload(payload) {
        this._roomcheckinPayload = payload;
    }

    get facilityId() {
        return this._facilityId;
    }

    set facilityId(id) {
        this._facilityId = id;
    }

    isPetTnCSaveSet(): boolean {
        return this.PetTncSaveError;
    }

    setPetTnCSaveSet(errorState: boolean): void {
        this.PetTncSaveError = errorState;
    }

    get petCount() {
        return this._petCount;
    }

    set petCount(petCount: number) {
        this._petCount = petCount;
    }

    isRRNSaveSet(): boolean {
        return this.rrnSaveError;
    }

    setRRNSaveSet(errorState: boolean): void {
        this.rrnSaveError = errorState;
    }

    isHACSaveSet(): boolean {
        return this.hacSaveError;
    }

    setHACSaveSet(errorState: boolean): void {
        this.hacSaveError = errorState;
    }

    isHAPSaveSet(): boolean {
        return this.hapSaveError;
    }

    setHAPSaveSet(errorState: boolean): void {
        this.hapSaveError = errorState;
    }

    isTnCSaveSet(): boolean {
        return this.tncSaveError;
    }

    setTnCSaveSet(errorState: boolean): void {
        this.tncSaveError = errorState;
    }

    isRatSaveSet(): boolean {
        return this.ratSaveError;
    }

    setRatSaveSet(errorState: boolean): void {
        this.ratSaveError = errorState;
    }

    isPaymentSheetLoaded(): boolean {
        return this.paymentSheetLoaded;
    }

    setPaymentSheetLoaded(paymentSheetLoaded: boolean): void {
        this.paymentSheetLoaded = paymentSheetLoaded;
    }

    getAPPSessionToken(): string {
        return this.sessionToken;
    }

    setAPPSessionToken(sessionToken: string): void {
        this.sessionToken = sessionToken;
    }

    getAddressIsValid(): boolean {
        return this._addressIsValid;
    }

    setAddressIsValid(value: boolean) {
        this._addressIsValid = value;
    }

    isAddressValid(address: Address): boolean {
        if (address === undefined) { return false; }

        const {addressLineOne, city, state, postalCode, country} = address;

        const isUSAOrCanada = ['US', 'USA', 'CA', 'CAN'].includes(country);

        const addressLineOneIsValid = addressLineOne !== undefined && addressLineOne.trim().length > 0;
        const cityIsValid = city !== undefined && city.trim().length > 0;
        const postalCodeIsValid = postalCode !== undefined && postalCode.length > 0;
        const countryIsValid = country !== undefined && country.length > 0;
        const stateIsValid = !isUSAOrCanada || (state !== undefined && state.length > 0);

        return addressLineOneIsValid &&
            cityIsValid &&
            postalCodeIsValid &&
            countryIsValid &&
            stateIsValid;
    }

    isReservationAddressValid(reservation: Reservation): boolean {
        const { address } = reservation.mdxAccountInfo;
        const accommodations = reservation.travelPlanSegmentDetails['accommodation'];
        let primaryAddressIsValid = false;

        if (accommodations) {
            accommodations.forEach((accommodation) => {
                if (accommodation.guestReferences) {
                    const primaryGuest = accommodation.guestReferences.filter((guestRef) => {
                        return guestRef.guest.primaryGuest;
                    })[0];

                    if (primaryGuest) {
                        primaryAddressIsValid = this.isAddressValid(primaryGuest.guest.primaryAddress);
                    }
                }
            });
        }

        return primaryAddressIsValid || this.isAddressValid(address);
    }

    getShouldHideEta(reservation: Reservation): boolean {
        if (!reservation || reservation === null ||
            !reservation.prearrivalInfo || reservation.prearrivalInfo === null) {
                return false;
            }

        const { showOlciEta } = reservation.prearrivalInfo;
        return !showOlciEta;
    }

    isBeachResortReservation(accommodations: Accommodation[]) {
        const accommodation = accommodations[0];
        const facilityId = accommodation.facilityId;
        if (facilityId === 80010406) {
            return 'hilton-web';
        } else if (facilityId === 80010405) {
            return 'vero-web';
        } else {
            return false;
        }
    }

    formatCacheData(data) {
        
        const formattedData: CacheDetails = {
            isHACExpanded: true,
            chargeAccountDetails: data.chargeAccountDetails,
            showHAPEditButton: this.isHAPSaveSet(),
            contactInfo: {
                mobileNumber: data.prearrivalInfo.mobilePhoneNumber || '',
                emailAddress: data.prearrivalInfo.emailAddress || '',
            },
            resortArrivalTime: data.prearrivalInfo.arrivalDepartureInfo?.arrivalTime || '',
            address: {
                addressLineOne: data.address?.addressLineOne || '',
                addressLineTwo: data.address?.addressLineTwo || '',
                city: data.address?.city || '',
                country: data.address?.country || '',
                postalCode: data.address?.postalCode || '',
                state: data.address?.state || '',
            },
            roomRequest: data.profileInfos?.[0].selectedList || [],
            houseKeepingOptIn: data.prearrivalInfo.serviceYourWayOptIn !== null ? data.prearrivalInfo.serviceYourWayOptIn : false
        };

        // Setting petCount into post /cache-details payload
        if (data.preferences && data.preferences.petInfo && data.preferences.petInfo.petCount >= 0) {
            formattedData.petCount = data.preferences.petInfo.petCount;
        }

        return formattedData;
    }

    generateRandomString(length: number): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const characterArray = Array.from({ length }, () => {
            const randomIndex = Math.floor(Math.random() * characters.length);
            return characters.charAt(randomIndex);
        });
        return characterArray.join('');
    }

}
